import { cn } from '@divlab/divanui';

import { useDevPanel } from '@Stores/DevPanel';
import { useInfiniteCategory } from '@Queries/useInfiniteCategory';
import useNavigation from '@Navigation/useNavigation';
import { useRequest } from '@Contexts/Request';
import tags from './data';
import styles from './LinearTag.module.css';

import type { FC, HTMLAttributes } from 'react';

interface LinearTagProps extends HTMLAttributes<HTMLDivElement> {
  type: TagType;
  size?: 'small' | 'middle';
  animation?: boolean;
  tagData?: PartialTagData;
}

export type TagType = 'extra' | 'kits';

export interface TagData {
  icon: string;
  color: string;
  text1: string;
  text2: string;
  startData: Date;
  endDate: Date;
}

export type PartialTagData = Omit<TagData, 'startData' | 'endDate'>;

const LinearTag: FC<LinearTagProps> = (props) => {
  const { className, type = 'kits', size, animation, tagData, ...restProps } = props;
  const { country, language } = useRequest();
  const {
    pathname,
    search,
    params: { slug },
  } = useNavigation();
  const { currentDate: devCurrentDate } = useDevPanel();
  const currentDate = devCurrentDate || new Date();
  const category = useInfiniteCategory({ slug });
  const pageCount = category.data ? category.data.pages.length : 1;

  // Для избавления от рассинхрона анимации в каталоге товаров при погрузке товаров или фильтрации
  const key = pageCount + pathname + search;

  const findedTag =
    tagData ||
    (tags[country]?.[language.id]?.[type] || []).find((tag) => {
      return tag.startData < currentDate && currentDate < tag.endDate;
    });

  if (!findedTag) return null;

  return (
    <div
      className={cn(styles.tag, className, {
        [styles.middle]: size === 'middle',
      })}
      style={{ backgroundColor: findedTag.color }}
      key={key}
      {...restProps}
    >
      <img className={styles.image} src={findedTag.icon} alt='' />
      <div className={cn(styles.text, { [styles.withAnimation]: animation })}>
        <div className={styles.text1}>{findedTag.text1}</div>
        <div className={styles.text2}>{findedTag.text2}</div>
      </div>
    </div>
  );
};

export default LinearTag;
