import { images } from './images';

import type { TagData, TagType } from './LinearTag';
import type { CountryCode, LanguageCode } from '@Types/Base';

type TagsData = Partial<Record<TagType, TagData[]>>;
type LocalData = Partial<Record<LanguageCode, TagsData>>;

const tags: Partial<Record<CountryCode, LocalData>> = {
  RUS: {
    ru: {
      kits: [
        {
          icon: images.plus,
          color: '#F0E5CF',
          text1: 'Вместе дешевле',
          text2: 'Кровать + Матрас',
          startData: new Date(2025, 1, 10, 10, 0, 0),
          endDate: new Date(2025, 2, 3, 23, 59, 59),
        },
      ],
    },
  },
};

export default tags;
