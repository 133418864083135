import { memo } from 'react';
import { cn, Loader } from '@divlab/divanui';

import useTranslation from '@Queries/useTranslation';
import { useRequest } from '@Contexts/Request';
import styles from './BuyButton.module.css';

import type { FC, HTMLAttributes } from 'react';

export interface BuyButtonProps extends HTMLAttributes<HTMLDivElement> {
  className?: string;
  href?: string;
  hasInCart?: boolean;
  waiting?: boolean;
}

/**
 * NOTE:
 * Разметка этой кнопки выполнена таким образом из-за определенных требований SEO
 * Поэтому не стоит изменять разметку без согласования с SEO
 */
const BuyButton: FC<BuyButtonProps> = (props) => {
  const { className, href, hasInCart, waiting, ...restProps } = props;
  const { t } = useTranslation();
  const { language } = useRequest();

  return (
    <div
      {...restProps}
      className={cn(
        styles.button,
        {
          [styles.hasInCart]: hasInCart,
          [styles.waiting]: waiting,
          [styles.uzLang]: language.id === 'uz',
          [styles.kkLang]: language.id === 'kk',
        },
        className,
      )}
    >
      {waiting && <Loader modalView className={styles.loader} />}
      <a className={cn(styles.link, 'add-to-card', 'BuyButton')} href={href}>
        {t('ui.buy')}
      </a>
    </div>
  );
};

export default memo(BuyButton);
